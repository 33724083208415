<script lang="ts">
	import type { CustomIcon } from '$lib/graphql/types'
	import type { CustomIconDefinitions } from '$lib/utils/custom-icons'
	import { stopPropagation } from '$lib/utils/clickHelpers'

	interface Props {
		categoryIcons?: CustomIconDefinitions[]
		categoryTitle?: string
		onclick: (icon: CustomIcon) => void
	}

	let { categoryIcons = [], categoryTitle = '', onclick }: Props = $props()

	function handleIconSelect(icon: CustomIcon) {
		onclick?.(icon)
	}
</script>

<p class="px-2 pt-4 pb-2 text-xs tracking-wide text-brand-gray-3">{categoryTitle}</p>
<div class="px-0 grid grid-cols-8 gap-0">
	{#each categoryIcons as icon}
		<button
			onclick={stopPropagation(() => handleIconSelect(icon.name))}
			class="hover:bg-brand-gray-6 p-2 rounded-md mx-auto touch-manipulation"
		>
			<img src={`/visuals/icons/custom/white/${icon.svgUrl}`} alt="icon select" class="h-6 w-6" />
		</button>
	{/each}
</div>
