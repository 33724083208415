<script lang="ts">
	import { getDndContext } from '$src/lib/context/dnd-context'
	import type { BlockPositionUpdateInput } from '$src/lib/graphql/types'
	import type { ExtendedBlockFieldsFragment } from '$src/lib/queries/generated/ExtendedBlockFields'
	import HighlightInList from './HighlightInList.svelte'

	interface Props {
		blockId: string
		orderedHighlights: ExtendedBlockFieldsFragment[]
		isPublic: boolean
		blocksUpdating: BlockPositionUpdateInput[]
		isLoading: boolean
	}

	let { blockId, orderedHighlights = [], isPublic, blocksUpdating, isLoading }: Props = $props()

	let highlightOrderElement: HTMLElement | null = $state(null)

	// Get DnD context
	const dndContext = getDndContext()

	$effect(() => {
		if (highlightOrderElement && !isPublic) {
			// Register container as drop target with initial position
			dndContext.registerDropTarget(highlightOrderElement, {
				id: `${blockId}-container`,
				parentId: blockId,
				position: orderedHighlights.length,
				type: 'extra',
			})

			// Cleanup
			return () => {
				dndContext.unregisterDropTarget(`${blockId}-container`)
			}
		}
	})

	// Register draggable blocks
	function registerBlockAction(
		element: HTMLElement,
		{ block, index }: { block: ExtendedBlockFieldsFragment; index: number },
	) {
		// Add data attribute for position calculation
		element.setAttribute('data-block-item', '')
		element.setAttribute('data-position', index.toString())
		element.setAttribute('data-block-id', block.id)

		dndContext.registerDraggable(element, {
			id: block.id,
			parentId: blockId,
			position: index,
			type: 'extra',
		})

		return {
			update({ block: newBlock, index: newIndex }) {
				if (!isPublic) {
					element.setAttribute('data-position', newIndex.toString())
					dndContext.registerDraggable(element, {
						id: newBlock.id,
						parentId: blockId,
						position: newIndex,
						type: 'extra',
					})
				}
			},
			destroy() {
				if (!isPublic) {
					dndContext.unregisterDraggable(block.id)
				}
			},
		}
	}

	// Handle touch events
	function handleTouchStart(e: MouseEvent | TouchEvent, blockId: string) {
		if (!isPublic) {
			// onsetMoving?.()
			dndContext.handleDragStart(e, blockId)
		}
	}
</script>

<div
	class="space-y-2"
	bind:this={highlightOrderElement}
	id={`${blockId}-highlight-order`}
	data-area="highlight-order"
	role="list"
>
	{#each orderedHighlights as block, index (block.id)}
		{@const isHighlightPositionUpdating = blocksUpdating.some((update) => update.id === block?.id)}
		<!-- svelte-ignore a11y_no_static_element_interactions -->
		<div
			class="flex items-center space-x-4 py-2 rounded-lg cursor-grab"
			use:registerBlockAction={{ block, index }}
			onmousedown={(e) => handleTouchStart(e, block.id)}
			ontouchstart={(e) => handleTouchStart(e, block.id)}
			data-block-item
			data-block-id={block.id}
		>
			<HighlightInList {block} {index} {isLoading} {isHighlightPositionUpdating} />
		</div>
	{/each}
</div>
