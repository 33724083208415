<script lang="ts">
	import Modal from './Modal.svelte'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { urlState } from '$lib/stores/url-state'
	import {
		BlockDocument,
		type BlockQuery,
		type BlockQueryVariables,
	} from '$lib/queries/generated/QueryBlock'
	import { browser } from '$app/environment'
	import Spinner from '$components/loading/Spinner.svelte'
	import ReorderBlocksList from './ReorderBlocksList.svelte'
	import {
		ItineraryDocument,
		type ItineraryQuery,
		type ItineraryQueryVariables,
	} from '$src/lib/queries/generated/QueryItinerary'
	import DestinationSegment from '$components/itineraries/blocks/DestinationSegment.svelte'
	import { BlockType } from '$src/lib/graphql/enums'
	import WrapperReorderBlocks from './WrapperReorderBlocks.svelte'

	let { tripId, blockId } = $derived($urlState)
	const client = getContextClient()
	let isLoading = $state(false)
	let isPublic = $derived($urlState.isPublic)

	let blockStore = $derived(
		queryStore<BlockQuery, BlockQueryVariables>({
			client,
			query: BlockDocument,
			variables: { id: blockId },
			requestPolicy: 'network-only',
			pause: !browser || !blockId || !blockId?.startsWith('blk_'),
		}),
	)

	let itineraryStore = $derived(
		queryStore<ItineraryQuery, ItineraryQueryVariables>({
			client,
			query: ItineraryDocument,
			variables: { itineraryId: blockId, tripId, first: 300 },
			requestPolicy: 'cache-first',
			pause: !browser || !tripId || !blockId?.startsWith('blk_'),
		}),
	)

	let block = $derived($blockStore?.data?.block ?? null)

	let descedantBlocks = $derived(
		$itineraryStore?.data?.itineraryConnection?.edges.map((edge) => edge?.node) ?? [],
	)

	let childBlocks = $derived(
		descedantBlocks?.filter((b) => b.parentId == block?.id).sort((a, b) => a.position - b.position),
	)
</script>

<Modal open={true} title="Reorder Blocks" size="sm:max-w-xl" isLightOverride={false}>
	<div class="flex flex-col space-y-4 pb-6">
		<p class="text-sm text-brand-gray-3">Drag and drop blocks to reorder them</p>
		{#if $blockStore?.fetching}
			<div class="flex justify-center py-4">
				<Spinner />
			</div>
		{:else if descedantBlocks.length === 0}
			<p class="text-sm text-brand-gray-4 text-center py-4">No blocks to reorder</p>
		{:else if block?.blockType !== BlockType.Destination}
			<WrapperReorderBlocks {blockId} {tripId} orderedBlocks={childBlocks} {isPublic} />
		{:else}
			<DestinationSegment
				{isPublic}
				isImmutable={false}
				{descedantBlocks}
				isDndModal={true}
				destinationBlock={block}
			/>
		{/if}
	</div>
</Modal>
