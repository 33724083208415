<script lang="ts">
	import { uniqueId } from 'lodash-es'
	import { validateEmail } from '$lib/utils/validate'
	import Button from '$lib/components/buttons/Button.svelte'

	interface Props {
		loading?: boolean
		formValid?: boolean
		buttonLabel?: string
		value?: string
		isLightOverride?: boolean
		onblur?: () => void
		onpaste?: () => void
		onsubmit?: (value: string) => void
		onchange?: (value: string, formValid: boolean) => void
	}

	let {
		loading = false,
		formValid = $bindable(false),
		buttonLabel = 'Sign In',
		value = '',
		isLightOverride = false,
		onblur,
		onpaste,
		onsubmit,
		onchange,
	}: Props = $props()

	let errorEmail: string = $state('')

	function handleClick() {
		errorEmail = validateEmail(value)
		if (errorEmail === '') {
			onsubmit?.(value)
		}
	}
	function onBlur(event: FocusEvent) {
		event.preventDefault()
		onblur?.()
	}
	function onPaste(event: ClipboardEvent) {
		event.preventDefault()
		onpaste?.()
	}
	function handleChange(event: Event) {
		event.preventDefault()
		const value = (event.target as HTMLInputElement)?.value?.trim()
		handleValue(value)
	}

	function handleKeyDown(event: KeyboardEvent) {
		errorEmail = validateEmail((event.target as HTMLInputElement)?.value?.trim())
		if (event.key === 'Enter' && errorEmail === '') {
			onsubmit?.(value)
		}
	}

	function handleValue(value: string) {
		errorEmail = validateEmail(value)
		formValid = errorEmail === ''
		onchange?.(value, formValid)
	}

	const id = uniqueId()
</script>

<div class="flex flex-col items-stretch w-full mt-2 space-y-2">
	<input
		{id}
		aria-autocomplete="list"
		class={`w-full h-12 p-4 leading-6 border rounded-md shadow-sm form-input focus:ring-1 border-grey-md focus:border-grey-dk focus:bg-grey-main focus:ring-gray-800 focus:outline-none focus:ring-transparent sm:text-sm ${
			isLightOverride
				? 'bg-white placeholder-brand-gray-5 text-black'
				: 'placeholder-brand-gray-5 dark:placeholder-brand-gray-2 bg-white dark:bg-brand-gray-5 text-black dark:text-white'
		}`}
		type="email"
		name="email"
		required
		autoComplete="email"
		placeholder="Enter your email"
		onblur={onBlur}
		onpaste={onPaste}
		oninput={handleChange}
		onkeydown={handleKeyDown}
		bind:value
	/>

	<span class={`text-red-500 text-xs h-[12px] ${errorEmail == '' && 'opacity-0'}`}>
		{errorEmail}
	</span>
	<Button onclick={handleClick} disabled={!formValid} size="md" class="w-full sm:w-auto" {loading}>
		{buttonLabel}
	</Button>
</div>
