<script lang="ts">
	import Modal from './Modal.svelte'
	import modal, { type ExtraTypeMap } from '$lib/stores/modal-state'
	import { track } from '$lib/utils/track'
	import Button from '$lib/components/buttons/Button.svelte'
	import {
		DeleteCouponDocument,
		type DeleteCouponMutation,
		type DeleteCouponMutationVariables,
	} from '$lib/queries/generated/PublishListing'
	import { getContextClient } from '@urql/svelte'

	const blockData = modal.extra<ExtraTypeMap, 'confirm-expire-coupon'>('confirm-expire-coupon')
	let couponId = $derived(blockData.couponId ?? '')

	const title = 'Expire Coupon'
	const description =
		'Are you sure you want to expire this coupon? It will no longer be available to buyers.'

	const client = getContextClient()

	const expireCoupon = (vars: DeleteCouponMutationVariables) =>
		client
			.mutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, vars)
			.toPromise()

	async function handleExpire() {
		track('Expire Coupon', { couponID: couponId })
		try {
			expireCoupon({ couponID: couponId })
			modal.close()
		} catch (error) {
			console.error('Failed to expire coupon:', error)
		}
	}
</script>

<Modal open={true} {title}>
	<div class="flex flex-col gap-6 p-6">
		<p class="text-brand-gray-6">{description}</p>

		<div class="flex gap-4 justify-end">
			<Button size="sm" theme="dark" onclick={modal.close}>Cancel</Button>

			<Button size="sm" theme="delete" onclick={handleExpire}>Expire Coupon</Button>
		</div>
	</div>
</Modal>
