<script lang="ts">
	import Modal from './Modal.svelte'
	import Button from '$lib/components/buttons/Button.svelte'
	import type { MutateTripContext } from '$lib/context/async-operations'
	import { getContext } from 'svelte'
	import modal from '$lib/stores/modal-state'
	import { urlState } from '$lib/stores/url-state'

	const mutateTrip = getContext<MutateTripContext>('mutateTrip')
	let tripId = $derived($urlState.tripId)

	const title = 'Unpublish Trip'
	const description =
		'Are you sure you want to unpublish this trip? It will no longer be visible to new buyers.'

	async function handleUnpublish() {
		try {
			mutateTrip.unpublishListing(tripId)
			modal.close()
		} catch (error) {
			console.error('Failed to unpublish trip:', error)
		}
	}
</script>

<Modal open={true} {title}>
	<div class="flex flex-col gap-6 p-6">
		<p class="text-brand-gray-6">{description}</p>

		<div class="flex gap-4 justify-end">
			<Button size="sm" theme="dark" onclick={modal.close}>Cancel</Button>

			<Button size="sm" theme="delete" onclick={handleUnpublish}>Unpublish Trip</Button>
		</div>
	</div>
</Modal>
