<script lang="ts">
	import { getDndContext } from '$src/lib/context/dnd-context'
	import BlockTemplate from '$src/lib/components/itineraries/blocks/BlockTemplate.svelte'
	import type { BlockFieldsFragment } from '$src/lib/queries/fragments/generated/BlockFields'
	import { getCustomColorBgClassName } from '$lib/utils/custom-colors'
	import { getCustomIconSvgUrl } from '$lib/utils/custom-icons'
	import ListSection from '$components/itineraries/blocks/ListSection.svelte'
	import { BlockType } from '$src/lib/graphql/enums'
	import type { BlockPositionUpdateInput } from '$src/lib/graphql/types'

	interface Props {
		blockId: string
		orderedBlocks: BlockFieldsFragment[]
		isPublic: boolean
		isLoading: boolean
		blocksUpdating: BlockPositionUpdateInput[]
	}

	let { blockId, orderedBlocks = [], isPublic, isLoading, blocksUpdating }: Props = $props()
	let blockOrderElement: HTMLElement | null = $state(null)
	const dndContext = getDndContext()

	$effect(() => {
		if (blockOrderElement && !isPublic) {
			dndContext.registerDropTarget(blockOrderElement, {
				id: `${blockId}-container`,
				parentId: blockId,
				position: orderedBlocks.length,
				type: 'list',
			})
			return () => {
				dndContext.unregisterDropTarget(`${blockId}-container`)
			}
		}
	})

	function registerBlockAction(
		element: HTMLElement,
		{ block, index }: { block: BlockFieldsFragment; index: number },
	) {
		element.setAttribute('data-block-item', '')
		element.setAttribute('data-position', index.toString())
		element.setAttribute('data-block-id', block.id)

		dndContext.registerDraggable(element, {
			id: block.id,
			parentId: blockId,
			position: index,
			type: 'list',
		})

		return {
			update({ block: newBlock, index: newIndex }) {
				if (!isPublic) {
					element.setAttribute('data-position', newIndex.toString())
					dndContext.registerDraggable(element, {
						id: newBlock.id,
						parentId: blockId,
						position: newIndex,
						type: 'list',
					})
				}
			},
			destroy() {
				if (!isPublic) {
					dndContext.unregisterDraggable(block.id)
				}
			},
		}
	}

	function handleTouchStart(e: MouseEvent | TouchEvent, blockId: string) {
		if (!isPublic) {
			dndContext.handleDragStart(e, blockId)
		}
	}
</script>

<div
	class="space-y-2"
	bind:this={blockOrderElement}
	id={`${blockId}-container`}
	data-area="block-order"
	role="list"
>
	{#each orderedBlocks as block, index (block.id)}
		{@const color = getCustomColorBgClassName(block?.color, block?.blockType)}
		{@const icon = getCustomIconSvgUrl(block?.icon, block?.blockType)}
		{@const isBlockPositionUpdating = blocksUpdating.some((update) => update.id === block?.id)}

		<!-- svelte-ignore a11y_no_static_element_interactions -->
		<div
			use:registerBlockAction={{ block, index }}
			onmousedown={(e) => handleTouchStart(e, block.id)}
			ontouchstart={(e) => handleTouchStart(e, block.id)}
			data-block-item
			data-block-id={block.id}
		>
			{#if block?.blockType === BlockType.List}
				<ListSection
					listBlock={block}
					numInList={block?.numInList}
					iconUrl={icon}
					bgColor={color}
					{isPublic}
					isDndModal={true}
					hasActions={false}
					{isLoading}
					{isBlockPositionUpdating}
				/>
			{:else}
				<BlockTemplate
					title={block.title}
					description={block.description}
					iconUrl={icon}
					bgColor={color}
					blockId={block.id}
					{isPublic}
					isDndModal={true}
					hasActions={false}
					{isLoading}
					{isBlockPositionUpdating}
				/>
			{/if}
		</div>
	{/each}
</div>
