<script lang="ts">
	import Modal from './Modal.svelte'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { browser } from '$app/environment'
	import { track } from '$lib/utils/track'
	import Button from '../buttons/Button.svelte'
	import modal from '$lib/stores/modal-state'
	import {
		CreateCouponDocument,
		type CreateCouponMutation,
		type CreateCouponMutationVariables,
	} from '$lib/queries/generated/PublishListing'
	import {
		ProfileDocument,
		type ProfileQuery,
		type ProfileQueryVariables,
	} from '$lib/queries/generated/QueryProfile'
	import authState from '$lib/stores/auth-state'
	import {
		FetchCouponCodesDocument,
		type FetchCouponCodesQuery,
		type FetchCouponCodesQueryVariables,
	} from '$lib/queries/generated/QueryStripeSeller'
	import { CouponState } from '$lib/graphql/enums'

	let owner = $authState?.currentUser

	const client = getContextClient()

	let isCreating = $state(false)

	let inputElementCode: HTMLInputElement = $state(null)
	let inputElementDiscount: HTMLInputElement = $state(null)

	let code = $state('')
	let discount = $state(20)
	let discountType = $state('regular') // 'regular' or 'full'

	let minDiscount = $state(5)
	let maxDiscount = $state(70)

	let discountError: string | null = $state(null)
	let codeError: string | null = $state(null)

	const createCode = (vars: CreateCouponMutationVariables) =>
		client.mutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, vars)

	let listingsStore = $derived(
		queryStore<ProfileQuery, ProfileQueryVariables>({
			client,
			query: ProfileDocument,
			variables: { id: owner?.id },
			pause: !browser || !owner?.id,
		}),
	)

	let storeCouponsStore = $derived(
		queryStore<FetchCouponCodesQuery, FetchCouponCodesQueryVariables>({
			client,
			variables: {},
			query: FetchCouponCodesDocument,
			requestPolicy: 'cache-only',
			pause:
				!browser ||
				!$authState.signedIn ||
				!$authState.authDidInitialize ||
				!$authState.currentUser?.hasSellerId,
		}),
	)

	let uniqueActiveCoupons = $derived(
		new Set(
			$storeCouponsStore?.data?.fetchCouponCodes
				?.filter((c) => c.state == CouponState.Active)
				?.map((coupon) => coupon.code)
				.filter(Boolean) ?? [],
		),
	)

	let listingIds = $derived(
		$listingsStore?.data?.profile?.publishedListings
			?.map((listing) => listing.id)
			.filter(Boolean) ?? [],
	)

	function handleCreateCode() {
		isCreating = true
		validateForm()
		if (!isCodeValid || !isDiscountValid) {
			isCreating = false
			return
		}
		track('Create Discount Code')

		const input = {
			code: inputElementCode?.value.toUpperCase(),
			discount: discountType === 'full' ? 100 : parseInt(inputElementDiscount?.value),
		}

		createCode({ listingIDs: listingIds, input }).then(() => {
			modal.close()
		})
	}

	let isCodeValid = false
	let isDiscountValid = false

	function validateForm() {
		validateCode()
		validateDiscount()
	}
	function validateCode() {
		const upperCaseCode = inputElementCode?.value.toUpperCase()
		const alphanumericRegex = /^[A-Z0-9]+$/

		// Remove any non-alphanumeric characters
		const cleanedCode = upperCaseCode.trim()

		inputElementCode.value = cleanedCode
		code = cleanedCode

		if (cleanedCode.length < 5) {
			isCodeValid = false
			codeError = 'Code must be at least 5 characters long'
		} else if (cleanedCode.length > 10) {
			isCodeValid = false
			codeError = 'Code must be no more than 10 characters long'
		} else if (!alphanumericRegex.test(cleanedCode)) {
			isCodeValid = false
			codeError = 'Code can only contain letters and numbers'
		} else if (uniqueActiveCoupons.has(cleanedCode)) {
			isCodeValid = false
			codeError = 'Code already exists'
		} else {
			isCodeValid = true
			codeError = null
		}
	}
	function validateDiscount() {
		if (discountType === 'full') {
			isDiscountValid = true
			discountError = null
			return
		}

		const discountValue = parseInt(inputElementDiscount?.value)
		if (discountValue < minDiscount) {
			isDiscountValid = false
			discountError = `Discount must be at least ${minDiscount}%`
		} else if (discountValue > maxDiscount) {
			isDiscountValid = false
			discountError = `Discount must be no more than ${maxDiscount}%`
		} else {
			isDiscountValid = true
			discountError = null
		}
	}

	function handleCodeInput() {
		inputElementCode.value = inputElementCode.value.toUpperCase()
		code = inputElementCode.value
	}

	function handleDiscountTypeChange() {
		if (discountType === 'full') {
			discount = 100
			discountError = null
		} else {
			discount = 20
			discountError = null
		}
	}
</script>

<Modal open={true} isLightOverride={false} size="sm:max-w-xl" title={'Create a Discount Code'}>
	<div class="my-8 flex space-x-4 w-full">
		<div class="w-full">
			<label for="code" class="text-xs dark:text-brand-gray-4">Code</label>
			<input
				type="text"
				id="code"
				bind:this={inputElementCode}
				value={code}
				onchange={validateForm}
				oninput={handleCodeInput}
				placeholder="YOUR CODE..."
				class="appearance-none text-sm border rounded-md dark:border-brand-gray-4 w-full mt-2 mb-2 px-3 dark:text-white dark:placeholder-brand-gray-4 bg-transparent leading-tight focus:outline-none focus:ring-0 dark:focus:border-brand-gray-1 dark:hover:border-brand-gray-1 transition-color uppercase"
			/>
			<p class="text-red-500 text-xs italic h-6 mt-1">{codeError ?? ''}</p>
		</div>

		<div class="w-full">
			<label for="discountType" class="text-xs dark:text-brand-gray-4">Discount Type</label>
			<select
				id="discountType"
				bind:value={discountType}
				onchange={handleDiscountTypeChange}
				class="appearance-none text-sm border rounded-md dark:border-brand-gray-4 w-full mt-2 mb-2 px-3 dark:text-white dark:bg-transparent leading-tight focus:outline-none focus:ring-0 dark:focus:border-brand-gray-1 dark:hover:border-brand-gray-1 transition-color"
			>
				<option value="regular">Regular Discount (up to 70%)</option>
				<option value="full">100% Off</option>
			</select>
		</div>
	</div>

	{#if discountType === 'regular'}
		<div class="mb-8 w-full">
			<label for="discount" class="text-xs dark:text-brand-gray-4">Discount Percentage</label>
			<input
				type="number"
				id="discount"
				bind:this={inputElementDiscount}
				bind:value={discount}
				onchange={validateForm}
				min={minDiscount}
				max={maxDiscount}
				placeholder="Set a discount percentage..."
				class="appearance-none text-sm border rounded-md dark:border-brand-gray-4 w-full mt-2 mb-2 px-3 dark:text-white dark:placeholder-brand-gray-4 bg-transparent leading-tight focus:outline-none focus:ring-0 dark:focus:border-brand-gray-1 dark:hover:border-brand-gray-1 transition-color"
			/>
			<p class="text-red-500 text-xs italic h-6 mt-1">{discountError ?? ''}</p>
		</div>
	{/if}

	<div class="flex flex-row justify-end">
		<Button
			onclick={handleCreateCode}
			loading={isCreating}
			disabled={isCreating}
			class="flex items-center w-28 space-x-3 font-light"
			size="sm"
			theme="brand"
		>
			Create
		</Button>
	</div>
</Modal>
