<script lang="ts">
  import Modal from './Modal.svelte'
  import modal from '$lib/stores/modal-state'
</script>

<Modal open={true} title="Filter" size="sm:max-w-md">
  <!-- Filter content will be added later as per requirements -->
  <div class="p-4">
    Filter options will go here
  </div>
</Modal>
