<script lang="ts">
	import DragAndDropProvider from '$lib/components/dnd/DragAndDropProvider.svelte'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import {
		UpdateBlockPositionsDocument,
		type UpdateBlockPositionsMutation,
		type UpdateBlockPositionsMutationVariables,
	} from '$lib/queries/generated/UpdateBlockPositions'
	import { getContextClient } from '@urql/svelte'
	import ReorderBlocksList from './ReorderBlocksList.svelte'
	import type { BlockFieldsFragment } from '$src/lib/queries/fragments/generated/BlockFields'
	interface Props {
		blockId: string
		tripId: string
		orderedBlocks: BlockFieldsFragment[]
		isPublic: boolean
	}

	let { blockId, tripId, orderedBlocks, isPublic }: Props = $props()

	let isLoading = $state(false)

	const client = getContextClient()

	let blocksUpdating: BlockPositionUpdateInput[] = $state([])

	async function handlePositionsUpdate(
		updates: BlockPositionUpdateInput[],
		movedBlockId: string | null,
	) {
		if (!updates.length) return
		isLoading = true
		blocksUpdating = updates
		try {
			await client.mutation<UpdateBlockPositionsMutation, UpdateBlockPositionsMutationVariables>(
				UpdateBlockPositionsDocument,
				{
					tripID: tripId,
					input: updates,
					isDestination: false,
					movedBlockId,
					isHighlight: false,
				},
			)
		} catch (error) {
			console.error('Error updating block positions:', error)
		} finally {
			isLoading = false
			blocksUpdating = []
		}
	}
</script>

<DragAndDropProvider
	destinationId={blockId}
	onPositionsUpdate={handlePositionsUpdate}
	disabled={isLoading}
>
	<ReorderBlocksList {blockId} {orderedBlocks} {isPublic} {isLoading} {blocksUpdating} />
</DragAndDropProvider>
