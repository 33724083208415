<script lang="ts">
	import Modal from './Modal.svelte'
	import DragAndDropProvider from '../dnd/DragAndDropProvider.svelte'
	import { getContextClient, queryStore } from '@urql/svelte'
	import { urlState } from '$lib/stores/url-state'
	import type { BlockPositionUpdateInput } from '$lib/graphql/types'
	import {
		UpdateBlockPositionsDocument,
		type UpdateBlockPositionsMutation,
		type UpdateBlockPositionsMutationVariables,
	} from '$lib/queries/generated/UpdateBlockPositions'
	import { browser } from '$app/environment'
	import Spinner from '$components/loading/Spinner.svelte'
	import {
		BlockDocument,
		type BlockQuery,
		type BlockQueryVariables,
	} from '$src/lib/queries/generated/QueryBlock'
	import { isMobile } from '$src/lib/utils/isMobile'
	import ReorderHighlightsList from './ReorderHighlightsList.svelte'

	let { tripId, blockId } = $derived($urlState)
	const client = getContextClient()
	let isLoading = $state(false)

	let isMobileScreen = $derived(isMobile())
	let isPublic = $derived($urlState.isPublic)

	let highlightStore = $derived(
		queryStore<BlockQuery, BlockQueryVariables>({
			client,
			query: BlockDocument,
			variables: { id: blockId },
			requestPolicy: 'network-only',
			pause: !browser || !blockId || !blockId?.startsWith('blk_'),
		}),
	)
	let orderedHighlights = $derived($highlightStore?.data?.block?.highlights ?? [])

	let blocksUpdating: BlockPositionUpdateInput[] = $state([])

	async function handlePositionsUpdate(
		updates: BlockPositionUpdateInput[],
		movedBlockId: string | null,
	) {
		if (!updates.length) return
		isLoading = true
		blocksUpdating = updates
		try {
			await client.mutation<UpdateBlockPositionsMutation, UpdateBlockPositionsMutationVariables>(
				UpdateBlockPositionsDocument,
				{
					tripID: tripId,
					input: updates,
					isDestination: false,
					movedBlockId,
					isHighlight: true,
				},
			)
		} catch (error) {
			console.error('Error updating highlight positions:', error)
		} finally {
			isLoading = false
			blocksUpdating = []
		}
	}
</script>

<Modal open={true} title="Reorder Highlights" size="sm:max-w-xl" isLightOverride={false}>
	<div class="flex flex-col space-y-4 pb-6">
		<p class="text-sm text-brand-gray-3">Drag and drop highlights to reorder them</p>
		<DragAndDropProvider
			destinationId={blockId}
			onPositionsUpdate={handlePositionsUpdate}
			disabled={isLoading}
		>
			{#if $highlightStore?.fetching}
				<div class="flex justify-center py-4">
					<Spinner />
				</div>
			{:else if orderedHighlights.length === 0}
				<p class="text-sm text-brand-gray-4 text-center py-4">No highlights to reorder</p>
			{:else}
				<ReorderHighlightsList
					{blockId}
					{orderedHighlights}
					{isPublic}
					{blocksUpdating}
					{isLoading}
				/>
			{/if}
		</DragAndDropProvider>
	</div>
</Modal>
