<script lang="ts">
	import modal from '$lib/stores/modal-state'
	import { getContext } from 'svelte'
	import type { MutateTripContext } from '$lib/context/async-operations'
	import { urlState } from '$lib/stores/url-state'
	import Modal from './Modal.svelte'
	import Button from '$components/buttons/Button.svelte'

	let tripId = $derived($urlState.tripId)

	const mutateTrip = getContext<MutateTripContext>('mutateTrip')

	function handleConfirm() {
		mutateTrip.downloadTripText(tripId)
		modal.close()
	}

	const title = 'Download Trip'
</script>

<Modal open={true} {title}>
	<div class="flex flex-col gap-6 p-6">
		<p class="text-brand-gray-6">
			Please confirm your download. Note that this might take up to a minute to complete.
		</p>
		<p class="text-brand-gray-6">This will not affect your trip in Airheart.</p>

		<div class="flex gap-4 justify-end">
			<Button size="sm" theme="dark" onclick={modal.close}>Cancel</Button>

			<Button size="sm" theme="brand" onclick={handleConfirm}>Download Trip</Button>
		</div>
	</div>
</Modal>
