<script lang="ts">
	import modal from '$lib/stores/modal-state'
	import AddCouponModal from './AddCouponModal.svelte'
	import CustomIconSelectModal from './CustomIconSelectModal.svelte'
	import FeaturedImagesModal from './FeaturedImagesModal.svelte'
	import HighlightMenuModal from './HighlightMenuModal.svelte'
	import SignInModal from './SignInModal.svelte'
	import ConfirmDeleteTrip from './ConfirmDeleteTrip.svelte'
	import ConfirmDeleteBlock from './ConfirmDeleteBlock.svelte'
	import ConfirmUnpublishTrip from './ConfirmUnpublishTrip.svelte'
	import EditDescriptionModal from './EditDescriptionModal.svelte'
	import ConfirmExpireCouponModal from './ConfirmExpireCouponModal.svelte'
	import FilterModal from './FilterModal.svelte'
	import HighlightOrderModal from './HighlightOrderModal.svelte'
	import BlockOrderModal from './BlockOrderModal.svelte'
	import ConfirmDownloadTrip from './ConfirmDownloadTrip.svelte'
</script>

{#if $modal == 'signup'}
	<!-- Handles all sub modal states, or 'intents' -->
	<SignInModal />
{/if}

{#if $modal == 'custom-icon'}
	<!-- Handles all sub modal states, or 'intents' -->
	<CustomIconSelectModal />
{/if}

{#if $modal == 'highlight-menu'}
	<!-- Handles all sub modal states, or 'intents' -->
	<HighlightMenuModal />
{/if}

{#if $modal == 'featured-images-menu'}
	<!-- Handles all sub modal states, or 'intents' -->
	<FeaturedImagesModal />
{/if}

{#if $modal == 'add-coupon'}
	<!-- Handles all sub modal states, or 'intents' -->
	<AddCouponModal />
{/if}

{#if $modal == 'confirm-delete-trip'}
	<ConfirmDeleteTrip />
{/if}

{#if $modal == 'confirm-delete-block'}
	<ConfirmDeleteBlock />
{/if}

{#if $modal == 'confirm-unpublish-trip'}
	<ConfirmUnpublishTrip />
{/if}

{#if $modal == 'edit-description'}
	<EditDescriptionModal />
{/if}

<!-- {#if $modal == 'localization-select'}
  <LocalizationSelectModal />
{/if} -->

{#if $modal == 'confirm-expire-coupon'}
	<ConfirmExpireCouponModal />
{/if}

{#if $modal == 'confirm-download-trip'}
	<ConfirmDownloadTrip />
{/if}

{#if $modal == 'filter'}
	<FilterModal />
{/if}

{#if $modal == 'highlight-order'}
	<HighlightOrderModal />
{/if}

{#if $modal == 'block-order'}
	<BlockOrderModal />
{/if}
