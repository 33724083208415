<script lang="ts">
	import { browser } from '$app/environment'
	import { page } from '$app/state'
	import { otpSignInRedirect } from '$lib/authentication/callbacks'
	import { seamlessAuthClientId } from '$lib/authentication/config'
	import { handleGoogleYoloCredential } from '$lib/authentication/firebase'
	import authState from '$lib/stores/auth-state'
	// import feature from '$lib/stores/feature'
	import { requestIdleCallback } from '$lib/utils/request-idle-callback'
	import { track } from '$lib/utils/track'
	import { once } from 'lodash-es'
	// import { track } from 'src/utils/track'
	import { onDestroy, onMount } from 'svelte'

	interface Props {
		isMapPage?: boolean
	}

	let { isMapPage = false }: Props = $props()

	// $: bannerFeatureEnabled = $feature['banner']
	let isBannerBlocked = $derived(page?.data?.blockBanner)

	let usingSeamlessSignIn = false

	const yoloConfig = {
		client_id: seamlessAuthClientId,
		context: 'signup',
		prompt_parent_id: 'g_id_container',
		cancel_on_tap_outside: true,
		auto_select: false,
		style: { top: '0', left: '0', padding: 0, position: 'relative' },
		callback: handleGoogleYoloCredential,
	}

	interface Notification {
		getDismissedReason(): string
		getMomentType(): string
		getNotDisplayedReason(): string
		getSkippedReason(): string
		isDismissedMoment(): boolean
		isDisplayMoment(): boolean
		isDisplayed(): boolean
		isNotDisplayed(): boolean
		isSkippedMoment(): boolean
	}

	const initSeamlessLogin = once(function initSeamlessLogin() {
		// @ts-ignore
		if (window?.google == null) return

		if ($authState.signedIn || $authState.isCompletingSignIn || !$authState.authDidInitialize)
			return
		// @ts-ignore
		window.google.accounts.id.initialize(yoloConfig)

		// @ts-ignore
		window.google.accounts.id.prompt((notification: Notification) => {
			if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
				console.debug('seamless sign in skipped', notification.getNotDisplayedReason())
				track('GoogleYolo Not Displayed', {
					reason: notification.getNotDisplayedReason(),
				})
			} else {
				track('GoogleYolo Displayed', {
					moment: notification.getMomentType(),
				})
			}
			usingSeamlessSignIn = notification.isDisplayed()
		})
	})

	function yoloOnload() {
		initSeamlessLogin()
	}

	let hasAddedScript = false
	let gsiScript: HTMLScriptElement

	onMount(() => {
		requestIdleCallback(() => {
			// Yolo Auth setup
			if ($authState.signedIn) return
			if (hasAddedScript) return
			hasAddedScript = true

			gsiScript = document.createElement('script')
			gsiScript.src = 'https://accounts.google.com/gsi/client'
			gsiScript.type = 'text/javascript'
			gsiScript.async = true
			gsiScript.defer = true
			gsiScript.setAttribute('fetchpriority', 'low')
			document.body.append(gsiScript)
			gsiScript.addEventListener('load', yoloOnload)
		})

		otpSignInRedirect(() => {})
	})

	onDestroy(() => {
		if (gsiScript == null) return
		gsiScript.removeEventListener('load', yoloOnload)
	})
</script>

{#if browser}
	<!-- svelte-ignore a11y_no_noninteractive_tabindex -->
	<div
		tabindex={-1}
		class={`fixed hidden sm:flex flex-col items-stretch sm:right-4 ${
			isMapPage ? 'top-4' : !isBannerBlocked ? 'sm:top-32' : 'sm:top-20'
		} bg-transparent z-[9900] max-w-[391px] align-bottom sm:bottom-auto`}
	>
		<div id="g_id_container"></div>
	</div>
{/if}
