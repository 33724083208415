<script lang="ts">
	import MoveIcon from '$components/icons/MoveIcon.svelte'
	import Spinner from '$components/loading/Spinner.svelte'
	import { MediaType } from '$src/lib/graphql/enums'
	import type { ExtendedBlockFieldsFragment } from '$src/lib/queries/generated/ExtendedBlockFields'
	import { constructCdnUrl, constructVideoThumbnailUrl } from '$src/lib/utils/cloudflare-loader'
	import { getCustomIconSvgUrl } from '$src/lib/utils/custom-icons'
	import { getCustomTitle } from '$src/lib/utils/custom-title'

	interface Props {
		block: ExtendedBlockFieldsFragment
		index: number
		isLoading: boolean
		isHighlightPositionUpdating: boolean
	}

	let { block, index, isLoading, isHighlightPositionUpdating }: Props = $props()

	let parentBlock = $derived(block?.parentBlock)
	let title = $derived(
		getCustomTitle(
			parentBlock?.title,
			parentBlock?.blockType,
			parentBlock?.position,
			block?.parentDestination?.title ?? null,
		),
	)
</script>

<div class="flex-shrink-0">
	{#if block?.highlight?.mediaType === MediaType.Video}
		<img
			class="h-16 w-16 rounded object-cover"
			src={constructVideoThumbnailUrl(block?.highlight?.cloudflareID)}
			alt={block.title ?? title ?? ''}
		/>
	{:else}
		<img
			class="h-16 w-16 rounded object-cover"
			src={constructCdnUrl(block?.highlight?.cloudflareID)}
			alt={block.title ?? title ?? ''}
		/>
	{/if}
</div>
<div class="flex-grow">
	<div class="flex items-center">
		<img
			src={getCustomIconSvgUrl(parentBlock?.icon, parentBlock?.blockType)}
			class="w-4 h-4 mr-2"
			alt={parentBlock?.icon ?? ''}
		/>
		<span class="text-sm font-medium text-white">
			{block.title ?? title ?? ''}
		</span>
	</div>
</div>
<div class="flex-shrink-0">
	{#if isLoading && isHighlightPositionUpdating}
		<Spinner size="sm" embedded />
	{:else}
		<MoveIcon class="w-4 h-4 text-brand-gray-4" />
	{/if}
</div>
