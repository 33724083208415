<script lang="ts">
	import modal from '$lib/stores/modal-state'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { fade, fly } from 'svelte/transition'
	import { onMount, onDestroy } from 'svelte'

	interface Props {
		open?: boolean
		size?: string
		title?: string
		showClose?: boolean
		isLightOverride?: boolean
		children?: import('svelte').Snippet
		actions?: import('svelte').Snippet
	}

	let {
		open = false,
		size = 'sm:max-w-md',
		title = '',
		showClose = true,
		isLightOverride = true,
		children,
		actions,
	}: Props = $props()

	let modalRef: HTMLElement | null = $state(null)
	let previouslyFocusedElement: HTMLElement | null = $state(null)

	onMount(() => {
		previouslyFocusedElement = document.activeElement as HTMLElement

		// Set focus on first focusable element
		setTimeout(() => {
			if (modalRef) {
				const focusableElements = modalRef.querySelectorAll(
					'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
				)
				if (focusableElements.length > 0) {
					;(focusableElements[0] as HTMLElement).focus()
				}
			}
		}, 50)
	})

	onDestroy(() => {
		// Restore focus
		if (previouslyFocusedElement) {
			previouslyFocusedElement.focus()
		}
	})

	function onClose() {
		modal.close()
	}
</script>

{#if open}
	<div class="fixed inset-0 z-50 cursor-pointer overscroll-contain overflow-y-auto">
		<div class="flex items-end justify-center min-h-[70dvh] h-full">
			<!-- Overlay -->
			<div
				class="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm touch-manipulation"
				transition:fade|local={{ duration: 100 }}
				onclick={stopPropagation(onClose)}
				role="none"
			></div>

			<!-- Modal Content -->
			<div
				bind:this={modalRef}
				role="dialog"
				aria-modal="true"
				class="inline-block w-screen cursor-auto text-left relative overflow-hidden
           {size} h-dvh sm:h-auto sm:my-auto sm:align-middle sm:rounded-lg shadow-xl
           {isLightOverride
					? 'text-black bg-white'
					: 'dark:text-brand-gray-2 text-black dark:bg-brand-gray-6 bg-white'}"
				transition:fly|local={{ duration: 150, opacity: 0, y: 40 }}
			>
				<div class="h-full flex flex-col">
					{#if title || showClose}
						<div
							class="relative flex items-start justify-between p-4 sm:p-6 pb-2 mb-4 text-lg font-medium leading-6"
						>
							{#if title}
								<span
									class="mt-2 leading-6 mr-8 {isLightOverride
										? 'text-black'
										: 'text-black dark:text-white'}"
								>
									{title}
								</span>
							{/if}

							{#if showClose}
								<button
									aria-label="Close"
									class="relative p-2 rounded-full hover:bg-brand-gray-4 dark:hover:bg-brand-gray-2 dark:hover:text-brand-gray-6 transition-all transform hover:scale-105 touch-manipulation"
									onclick={stopPropagation(onClose)}
								>
									<svg
										class="w-5 h-5"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							{/if}
						</div>
					{/if}

					<div class="flex-1 overflow-y-auto px-4 sm:px-6">
						<div class="{size} sm:mx-auto pb-[50dvh] sm:pb-6">
							{@render children?.()}
						</div>
					</div>

					{#if actions}
						<div
							class="sticky bottom-0 left-0 right-0 p-4 sm:p-6 sm:pt-0 sm:pb-8 bg-inherit border-t sm:border-t-0 border-brand-gray-2 dark:border-brand-gray-4"
						>
							<div class="flex gap-3 justify-end max-w-md sm:max-w-none mx-auto">
								{@render actions?.()}
							</div>
						</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
{/if}
