<script lang="ts">
	// Svelte 5 runes
	let { readonly } = $props<{
		size?: string
		class?: string
		ariaHidden?: boolean
	}>()

	// Default props using derived state
	const defaultedSize = $derived(readonly?.size ?? '1.25rem')
	const defaultedClass = $derived(readonly?.class ?? '')
	const defaultedAriaHidden = $derived(readonly?.ariaHidden ?? true)
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
	stroke-width="2"
	width={defaultedSize}
	height={defaultedSize}
	class={defaultedClass}
	aria-hidden={defaultedAriaHidden}
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
	/>
</svg>
