<script lang="ts">
	import Modal from './Modal.svelte'
	import Button from '$lib/components/buttons/Button.svelte'
	import type { MutateTripContext } from '$lib/context/async-operations'
	import { getContext } from 'svelte'
	import modal, { type ExtraTypeMap } from '$lib/stores/modal-state'
	import { urlState } from '$lib/stores/url-state'
	import { BlockType } from '$lib/graphql/enums'

	const mutateTrip = getContext<MutateTripContext>('mutateTrip')
	let tripId = $derived($urlState.tripId)

	// Get block data from extra store
	const blockData = modal.extra<ExtraTypeMap, 'confirm-delete-block'>('confirm-delete-block')
	const { blockId, blockType, blockTitle } = blockData

	const title = `Delete ${blockType === null ? 'Itinerary' : 'Block'}`
	const description = blockTitle
		? `Are you sure you want to delete "${blockTitle}"? This action cannot be undone.`
		: `Are you sure you want to delete this ${
				blockType === null ? 'Itinerary' : 'Block'
			}? This action cannot be undone.`

	async function handleDelete() {
		if (!blockId || !tripId) {
			console.error('Missing required block data')
			return
		}

		try {
			mutateTrip.deleteBlock(tripId, blockId, blockType ?? BlockType.Root)
			modal.close()
		} catch (error) {
			console.error('Failed to delete block:', error)
		}
	}
</script>

<Modal open={true} {title}>
	<div class="flex flex-col gap-6 p-6">
		<p class="text-brand-gray-6">{description}</p>

		<div class="flex gap-4 justify-end">
			<Button size="sm" theme="dark" onclick={modal.close}>Cancel</Button>

			<Button size="sm" theme="delete" onclick={handleDelete}>Delete</Button>
		</div>
	</div>
</Modal>
